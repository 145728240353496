











































































































import { Component, Mixins } from "vue-property-decorator";
import SubAccountListComponent from "./SubAccountList.component";

@Component
export default class SubAccountList extends Mixins(SubAccountListComponent) {}
