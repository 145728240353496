import { Component, Vue } from "vue-property-decorator";
import { GET_SUB_ACCOUNT_AGENT } from "../../../../api/account"
import moment from 'moment';
import Model from "@/models/Model";
import VModal from 'vue-js-modal'

Vue.use(VModal, { componentName: 'modal' })
@Component({
    filters: {
        currency(value: any) {
            const val = (value / 1).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        dateTime(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        }
    }
})
default class SubAccountList extends Vue {
    $axios: any;
    private subAccountData: any = []
    private model: Model;
    private cookieData: any = Vue.$cookies.get("login")
    public dateFilter = {
        startDate: moment().local().startOf('day').toDate(),
        endDate: moment().local().endOf('day').toDate()
    }

    private userId = ""

    private paginate = {
        page: 1,
        limit: 10
    }
    private pageSize = 0;
    private paginateShow = false;
    private dataLoading = false;

    constructor() {
        super();
        this.model = new Model();
    }

    async mounted() {
        this.addRouteName()
        this.dataLoading = true
        await this.getSubAccountDataById(this.cookieData.realUsername, this.paginate.page, this.paginate.limit)
        this.dataLoading = false
    }

    private addRouteName() {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

    public async clickCallback(number: number) {
        this.paginate.page = number
        await this.getSubAccountDataById(this.cookieData.realUsername, this.paginate.page, this.paginate.limit)
    }

    private async getSubAccountDataById(user: string, page: number, limit: number) {
        this.subAccountData = []
        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });
        const lists = await GET_SUB_ACCOUNT_AGENT(page, limit)
        this.subAccountData = lists.data[0];

        if (this.subAccountData.metadata.length >= 1) {
            const num = (this.paginate.page * this.paginate.limit)
            for (let i = 0, k = num; i < this.subAccountData.metadata.length; i++, k++) {
                for(const value of this.subAccountData.metadata[i].subAccountMenus)
                {
                    let valuePermission = ""
                    if(value.permission == 'readonly')
                    {
                        valuePermission = "VIEW"
                    } else if(value.permission == 'editable')
                    {
                        valuePermission = "EDIT"
                    } else
                    {
                        valuePermission = "OFF"
                    }
                    this.subAccountData.metadata[i][value.name] = valuePermission
                }
                this.subAccountData.metadata[i].no = k - this.paginate.limit
                this.subAccountData.metadata[i].editable = false
            }
            if (this.subAccountData.pageInfo[0].count > this.paginate.limit) {
                this.paginateShow = true
            }
            this.pageSize = this.subAccountData.pageInfo[0].size;
        } else {
            this.subAccountData = []
            this.paginateShow = false
        }
        
        loader.hide()
    }
}

export default SubAccountList